<template lang="pug">
.upload-app#uploadApp
  notifications
  .alert.alert-danger(v-show="error")
    strong
      icon.fa-fw(name="exclamation-triangle")
      |  {{ error }}
  .upload-uploadPassword(v-if='showLogin', @submit.prevent='setUploadPass()')
    .panel.panel-translucent(style="max-width: 600px; margin: auto; margin-top: 100px;")
      .panel-heading
        span {{ $root.lang.uploadPassword }}
      .panel-body
        form
          .form-group
            input.form-control(type='password', v-model='uploadPassword', autofocus)
          p.text-danger(v-show='uploadPasswordWrong')
            strong {{ $root.lang.accessDenied }}
          |
          button.uploadPass.btn.btn-info.btn-border.btn-block(:disabled='uploadPassword.length<1', type="submit")
            icon.fa-fw(name="key")
            |  {{ $root.lang.login }}
  div(v-else-if="$root.configFetched")
    .overall-process-box 
      .overall-process(v-show="state === 'uploading'")
        .upload-pic
          img(src='assets/media/mountain.png')
        .upload-ibex(:style="{left: calcIbexPos(percentUploaded) + 'px'}")
          img(src='assets/media/ibex.png')
        .upload-pic-end
          img(src='assets/media/mountain.png')
    div(v-show="state === 'uploaded'")
      .panel.panel-translucent(style="max-width: 600px; margin: auto; margin-top: 80px;")
        .panel-heading
          strong {{ $root.lang.uploadCompleted }}
        .panel-body.text-center
          div.share-link
            strong {{ $root.lang.downloadLink }}:
            .input-group(style="margin-top: 10px;")
              input#password.form-control(
                type='text'
                :value='shareUrl'
                readonly
              )
              span.input-group-btn
                a.btn.btn-info(:href="shareUrl", target="_blank")
                  icon(name="link", style="fill: #fff;")
          div(style="margin-top: 20px; margin-bottom: 10px;")
            strong {{ $root.lang.downloadLinkShare }}:
            br
            .btn-group.btn-group-sm.upload-success-btns(style="margin-top: 10px;")
              a.btn.btn-info(v-if="!disableQrCode", @click.prevent="showQrCode" href="#" :title="$root.lang.showQrCode")
                icon.fa-fw(name="qrcode")
                | QR
              a.btn.btn-info(:href="mailLnk" :title="$root.lang.sendViaMail")
                icon.fa-fw(name="envelope")
                | {{ $root.lang.email }}
              a.btn.btn-info(v-show="canShare()", @click.prevent="nativeShare()", href="#", :title="$root.lang.share")
                icon.fa-fw(name="share-alt")
                | {{ $root.lang.share }}
              clipboard.btn.btn-info(:value='shareUrl' :title="$root.lang.copyToClipboard")
      .text-center(style="margin-top: 30px;")
        a.btn.btn-md.btn-info.btn-border.btn-new-session(v-if='!showLogin', @click='newSession()', :title='$root.lang.newUpload')
          icon.fa-fw(name="cloud-upload-alt")
          span.hidden-xs  {{ $root.lang.newUpload }}
    div(v-show="state !== 'uploaded'", style="margin-top: 30px;")
      .panel.panel-translucent(:style="files.length == 0 ? 'max-width: 600px; margin: auto;' : 'max-width: 900px; margin: auto;'")
        .panel-heading(v-show="files.length == 0")
          strong Swibex -
          span
          |        {{ $root.lang.tagLine }}
        .panel-heading(v-show="files.length > 0")
          span.pull-right(v-show="bucketSize > 0") {{ humanFileSize(bucketSize) }}
          strong {{ $root.lang.files }}
        .panel-body(style="min-height: 40vh;")
          .row
            .pg_files(v-show="state !== 'uploaded'", :class="files.length == 0 ? 'col-md-12' : 'col-md-8'")
              files
            .col-md-4.pg_settings(v-show="files.length != 0 && state !== 'uploaded'")
              settings
              .pg_btn_upload.text-right(v-show='showUploadBtn')
                button#uploadBtn.btn.btn-md.btn-info.btn-border.btn-block(@click="$store.dispatch('upload/upload')")
                  icon.fa-fw(name="upload")
                  |  {{ $root.lang.upload }}
              .pg_btn_upload.text-right(v-show="state === 'uploadError'")
                button#uploadRetryBtn.btn.btn-md.btn-info.btn-border.btn-block(@click="$store.dispatch('upload/upload')")
                  icon.fa-fw(name="upload")
                  |  {{ $root.lang.retry }}
        .panel-footer 
          conditions
    social
</template>

<script type="text/babel">
  import { Encoder, ErrorCorrectionLevel, QRByte } from "@nuintun/qrcode";
  import { mapState, mapGetters } from 'vuex';

  import Settings from './Upload/Settings.vue';
  import Files from './Upload/Files.vue';
  import Clipboard from './common/Clipboard.vue';
  import Social from './common/Social.vue';
  import Conditions from './common/Conditions.vue';
  import 'vue-awesome/icons/cloud-upload-alt';
  import 'vue-awesome/icons/upload';
  import 'vue-awesome/icons/envelope';
  import 'vue-awesome/icons/qrcode';
  import 'vue-awesome/icons/share-alt';
  import 'vue-awesome/icons/link';
  import 'vue-awesome/icons/exclamation-triangle';
  import { humanFileSize } from "./Upload/store/upload";


  export default {
    name: 'Upload',
    components: {
      Settings,
      Files,
      Clipboard,
      Social,
      Conditions
    },

    data() {
      return {
        uploadPassword: '',
        uploadPasswordWrong: null,
      }
    },

    computed: {
      ...mapState(['state']),
      ...mapState('config', ['uploadPassRequired', 'uploadPass', 'requireBucketPassword', 'disableQrCode']),
      ...mapState('upload', ['sid', 'files', 'password']),
      ...mapGetters(['error', 'disabled']),
      ...mapGetters('upload', ['percentUploaded', 'shareUrl', 'bucketSize', 'bytesUploaded']),
      mailLnk: function() {
        if (this.password !== undefined && this.password.length > 0) {

          var template = this.$root.lang.mailTemplatePassword || "";
          template = template.replace('%%URL%%', this.shareUrl);
          template = template.replace('%%PASS%%', this.password);
          return template;

        } else {

          var template = this.$root.lang.mailTemplate || "";
          template = template.replace('%%URL%%', this.shareUrl);
          return template;
  
        }
      },
      showLogin() {
        return this.uploadPassRequired && this.uploadPasswordWrong !== false;
      },
      showUploadBtn() {
        return this.files.length
          && !this.disabled
          && (this.requireBucketPassword && this.password || !this.requireBucketPassword)
      }
    },

    watch: {
      state: function(val) {
        if (val === 'uploaded' || val === 'uploadError') {
          const el = document.getElementById('uploadApp');
          if (!el || !el.scrollIntoView) return;
          el.scrollIntoView(true);
        }
      },
      notif: function(notif) {
        if (notif) {
          console.log("test");
        }
      }
    },

    methods: {
      newSession() {
        if (!confirm(this.$root.lang.createNewUploadSession)) return;
        document.location.reload();
      },
      async setUploadPass() {
        try {
          this.$store.commit('config/SET', {uploadPass: this.uploadPassword});
          await this.$store.dispatch('config/fetch');
          this.uploadPasswordWrong = false;
        } catch(e) {
          if(e.code === 'PWDREQ') {
            this.uploadPassword = '';
            this.uploadPasswordWrong = true;
          } else {
            console.error(e);
          }
        }
      },
      onClickTop() {
        this.$notify('Hello user!')
      },
      showQrCode() {
        const qrcode = new Encoder();
        qrcode.setEncodingHint(true);
        qrcode.setErrorCorrectionLevel(ErrorCorrectionLevel.H);
        qrcode.write(new QRByte(this.shareUrl));
        qrcode.make();
        const imgSrc = qrcode.toDataURL(7, 10);
        const data = imgSrc.substr(imgSrc.indexOf(',') + 1);
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'image/gif;base64' });
        window.open(URL.createObjectURL(file));
      },
      humanFileSize,
      canShare() {
        return navigator.share;
      },
      nativeShare() {
        navigator.share({
          title: this.$root.lang.shareTitle,
          url: this.shareUrl,
        });
      },
      calcIbexPos(percent) {
        if (!percent) {
          percent = 0;
        }

        if (document.getElementsByClassName("overall-process")[0] === undefined) {
          return 0;
        }
        
        var width = document.getElementsByClassName("overall-process")[0].offsetWidth;
        var offset = ((width - 80) / 100 * percent) - 80;

        return offset;
      }
    }

  }
</script>

<style>
  @media all and (max-width: 500px) {
    .upload-success-btns .btn {
      padding: 7px 5px;
      font-size: 12px;
    }
  }
</style>
