<template lang="pug">
div.upload-files  
  div(v-show="files.length == 0")
    .empty-files-big-plus(
      :style="{cursor: disabled ? 'default' : 'pointer', marginTop: '10vh'}",
      onclick="document.getElementById('fileInput').click();",
      v-show="files.length === 0"
    )
      a
        img(src="assets/media/upload.png" alt="logo" style="width: 100px;" class="").pg_effects
      .pg_tooltip
        p {{ $root.lang.clickOrDropFiles }}
  .pg_drop_table(v-show="files.length != 0")
    .pg_drop_table2
      .pg_files_table
        table.table
          tbody
            tr(v-for="file in files")
              td.file-icon
                file-icon(:file="file._File")
              td
                .row(style="margin-bottom: 5px;")
                  .col-sm-9
                    strong {{ file.name }}
                  .col-sm-3.text-right
                    span.label.label-info {{ file.humanSize }}
                p
                  input.form-control.input-sm(type="text", :placeholder="$root.lang.comment", v-model="file.comment", :disabled="disabled")
                .alert.alert-danger(v-if="file.error")
                  icon.fa-fw(name="exclamation-triangle")
                  |  {{ file.error }}
                .progress(v-show="!file.error && (state === 'uploading' || state === 'uploaded')")
                  .progress-bar.progress-bar-success.progress-bar-striped(:style="{width: file.progress.percentage+'%'}", :class="{active:!file.uploaded}")
              td.btns
                a(style="cursor:pointer"
                  @click="$store.dispatch('upload/removeFile', file)"
                  v-show="!disabled || bucketSizeError"
                )
                  span.simple-tooltip
                    icon(name="times-circle", style="fill: #e74c3c;")
                    span.tooltiptext-left {{ $root.lang.delete }}

    input#fileInput(type="file", @change="$store.dispatch('upload/addFiles', $event.target.files)", multiple="", :disabled="disabled", style="display: none")
    .text-center
      a.btn.btn-success.btn-sm.btn_pg_icon(onclick="document.getElementById('fileInput').click();", :disabled="disabled", v-show="files.length>0")
        img(src="assets/media/more.png" alt="Add more" style="width: 50px;" class="").pg_effects
</template>


<script type="text/babel">
  import dragDrop from 'drag-drop';
  import 'vue-awesome/icons/exclamation-triangle'
  import 'vue-awesome/icons/plus'
  import 'vue-awesome/icons/plus-circle'
  import 'vue-awesome/icons/times-circle'
  import { mapGetters, mapState } from 'vuex';
  import FileIcon from '../common/FileIcon.vue';
  import { humanFileSize } from "./store/upload";

  export default {
    name: 'Files',

    components: { FileIcon },

    computed: {
      ...mapState('upload', ['files']),
      ...mapState(['state',]),
      ...mapGetters('upload', ['bucketSize', 'bucketSizeError']),
      ...mapGetters(['disabled']),
    },

    mounted() {
      // init drop files support on <body>
      this.dragDropCleanup = dragDrop('body', files => this.$store.dispatch('upload/addFiles', files));
    },

    watch: {
      state: function(state) {
        if(state === 'uploading') {
          this.dragDropCleanup();
         
        }
      }
    },

    methods: {
      humanFileSize,
    }
  };
</script>

  