<template>
  <div>
    <Transition name="modal" v-if="showModal" @close="showModal = false">
      <div class="vue-modal-mask">
        <div class="vue-modal-wrapper">
          <div class="vue-modal-container">

            <div class="vue-modal-header">
              <button class="btn btn-danger btn-sm pull-right" @click="showModal = false">
                <icon name="times" class="fa-fw" style="fill: #fff;"></icon>
              </button>

              {{ $root.lang.conditions }}
            </div>

            <div class="vue-modal-body">
              <div v-html="content"></div>
            </div>

            <div class="vue-modal-footer" style="text-align: right;">
              <button class="btn btn-info btn-sm" @click="showModal = false">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <div style="text-align: center;">
      <a href="javascript:;" id="show-modal" @click="showModalFunc()">{{ $root.lang.conditions }}</a>
    </div>
  </div>
</template>

<script type="text/babel">
  import 'vue-awesome/icons/times';

  export default {
    name: 'Conditions',
    components: {},

    data() {
      return {
        showModal: false,
        content: "",
      }
    },

    methods: {
      showModalFunc() {
        this.getContent();
        this.showModal = true;
      },
      getContent() {
        var setContent = this.setContent;
        const lang = this.$root.lang.langCode || 'fr';
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'assets/conditions/'+lang+'.html');
        xhr.onload = function() {
          if (xhr.status === 200) {
           setContent(xhr.responseText);
          }
        };
        xhr.send();
      },
      setContent(html) {
        this.content = html;
      }
    },
  }
</script>

<style>
  .vue-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .vue-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    overflow-y: scroll;
  }

  .vue-modal-container {
    max-width: 900px;
    width: 95%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #333;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    max-height: 90vh;
    overflow-y: scroll;
  }

  .vue-modal-header {
    font-weight: bold;
  }

  .vue-modal-body {
    margin: 20px 0;
  }

  .vue-modal-body h2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .vue-modal-body p {
    font-size: 11px;
    margin: 0 0 5px;
  }

  .vue-modal-body li {
    font-size: 11px;
  }

  .vue-modal-enter {
    opacity: 0;
  }

  .vue-modal-leave-active {
    opacity: 0;
  }

  .vue-modal-enter .vue-modal-container,
  .vue-modal-leave-active .vue-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  #show-modal {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
  }
</style>