<template lang="pug">
div.text-center.social-box
  p Made with ❤️ by
    |   
    a(href="https://progest.ch", target="_blank") Progest SA
  p.description(v-html="$root.lang.hostedInSwitzerland.replaceAll('%FLAG%', '<img style=\"margin: 0 5px;\" src=\"assets/flags/ch.png\">')")
  .social-icons
    .simple-tooltip
      a(href="https://instagram.com/progestsa", target="_blank").social-icon
        icon.fa-social(name="brands/instagram")
      span.tooltiptext Instagram
    .simple-tooltip
      a(href="https://facebook.com/progestsa", target="_blank").social-icon
        icon.fa-social(name="brands/facebook")
        span.tooltiptext Facebook
    span.social-icon(@click.stop="displayShare = !displayShare")
      .simple-tooltip
        icon.fa-social(name="share-alt")
        span.tooltiptext {{ $root.lang.share }}
    .share-hidden(v-show="displayShare === true", style="margin-top: 10px;")
      a(href="https://www.facebook.com/sharer.php?t=Swibex&u=https://swibex.com", target="_blank")
        icon.fa-fw(name="brands/facebook")
        | Facebook
      a(href="https://twitter.com/intent/tweet?text=Swibex: send your files for free!&url=https://swibex.com", target="_blank")
        icon.fa-fw(name="brands/twitter")
        | Twitter
      a(href="https://www.blogger.com/blog-this.g?n=Swibex&t=&u=https://swibex.com", target="_blank")
        icon.fa-fw(name="brands/blogger")
        | Blogger
      a(href="https://www.pinterest.com/pin/create/button/?description=Swibex&media=&url=https://swibex.com", target="_blank")
        icon.fa-fw(name="brands/pinterest")
        | Pinterest
      a(href="mailto:?subject=Swibex&body=https://swibex.com", target="_blank")
        icon.fa-fw(name="inbox")
        | Email
      a(href="https://www.reddit.com/submit?url=https://swibex.com&title=Swibex", target="_blank")
        icon.fa-fw(name="brands/reddit")
        | Reddit
      a(href="https://www.linkedin.com/sharing/share-offsite/?url=https://swibex.com", target="_blank")
        icon.fa-fw(name="brands/linkedin")
        | LinkedIn
</template>

<script type="text/babel">
  import 'vue-awesome/icons/brands/instagram';
  import 'vue-awesome/icons/brands/facebook';
  import 'vue-awesome/icons/brands/twitter';
  import 'vue-awesome/icons/brands/blogger';
  import 'vue-awesome/icons/brands/reddit';
  import 'vue-awesome/icons/brands/pinterest';
  import 'vue-awesome/icons/brands/linkedin';
  import 'vue-awesome/icons/inbox';
  import 'vue-awesome/icons/share-alt';

  export default {
    name: 'Social',
    components: {},

    data() {
      return {
        displayShare: false,
      }
    },
  }
</script>
